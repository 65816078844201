// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from 'react-router-dom';
// import ReactGA from "react-ga4";

// ReactGA.initialize("G-JGRM767V17");

// ReactGA.send({ 
//   hitType: "pageview", 
//   page: window.location.pathname, 
// });

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//  </React.StrictMode>,
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();



import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";
import Snowfall from "react-snowfall";
import { isMobile } from "react-device-detect"; // Import the device detection library

// Initialize Google Analytics
ReactGA.initialize("G-JGRM767V17");

ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

// Adjust snowflake count based on device
const foregroundSnowflakeCount = isMobile ? 50 : 200;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* App Content */}
      <div style={{ position: "relative", zIndex: 2 }}>
        <App />
      </div>
    </BrowserRouter>
  </React.StrictMode>,
);

// Measure performance in the app
reportWebVitals();
