import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import Button from "../elements/Button";
import { FaPhoneAlt } from "react-icons/fa";
import { FaTimes } from "react-icons/fa"; // Import the close icon
import BrandIcon from "./BrandIcon";
import { IoIosAdd } from "react-icons/io";



export default function Header() {
  const [isCollapse, setIsCollapse] = useState(false);
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
  const location = useLocation();
  const path = location.pathname;

  const handleButtonClick = () => {
    setIsBoxOpen(!isBoxOpen);
    setIsCollapse(!isCollapse);
  };

  const handleCloseMenu = () => {
    setIsCollapse(false);
  };
  
  const handleMouseEnter = () => {
    setIsDropdownOpen(true); // Open dropdown on hover
  };
  
  const handleMouseLeave = () => {
    setIsDropdownOpen(false); // Close dropdown when hover is removed
  };

  return (
    <header className="header">
   
     <div className="flex justify-between px-0 lg:px-5 items-center">
       <BrandIcon />
        {/* <div className="bg-white rounded-full opacity-80">
         
       </div> */}

        <li className="lg:hidden flex items-center ml-auto mr-2">
              <Button
                className="rgb-button font-medium px-5 py-4 text-white rounded-full transition duration-200 flex items-center "
                type="link"
                href="/discuss-project"
               >
                Contact
                <FaPhoneAlt size={20} className="ml-2 animate-bounce" /> 
              </Button>
        </li>
        

        {/* Mobile and desktop menu button */}
        <li className="lg:hidden p-[10px] mr-4 bg-theme-purple rounded-full  list-none">
          <button
            className="block text-white  focus:outline-none lg:hidden"
            onClick={handleButtonClick}
          >
            <svg
              className="w-10 h-10" // Adjusted size to 40px
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                className={`${isCollapse ? "hidden" : "block"}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
              <path
                className={`${!isCollapse ? "hidden" : "block"}`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </li>
      </div>


      <div className="container mx-auto ">
        <div className="flex items-center justify-between">
          {/* Desktop menu */}
          <ul
            className="hidden lg:flex flex-row justify-between items-center rounded-full border-2 bg-white py-1 lg:px-3  w-full "
            style={{ backgroundColor: "rgba(255, 255, 255, 0.9)" }}
          >
            <li className="flex items-center pl-4">
              <Button type="link" href="/">
                <p className="text-theme-blue text-4xl font-medium">
                  SCODE<span className="text-theme-purple">SOFT</span>
                </p>
              </Button>
            </li>
           

            <li className="flex items-center py-4 lg:py-0">
              <Button
                className={`${
                  path === "/services" ? "active-link" : ""
                } font-semibold text-theme-blue text-lg 2xl:text-2xl pl-8 2xl:pl-16 no-underline hover:underline`}
                type="link"
                href="/services"
              >
                Services
              </Button>
            </li>

            <li className="flex items-center py-4 lg:py-0">
              <Button
                className={`${
                  path === "/about" ? "active-link" : ""
                } font-semibold text-theme-blue text-lg 2xl:text-2xl px-10 no-underline hover:underline`}
                type="link"
                href="/about"
              >
                About Us
              </Button>
            </li>

            <li className="flex items-center py-4 lg:py-0">
              <Button
                className={`${
                  path === "/product" ? "active-link" : ""
                } font-semibold text-theme-blue text-lg 2xl:text-2xl no-underline hover:underline`}
                type="link"
                href="/product"
              >
              Products
              </Button>
            </li>

            <li className="flex items-center py-4 lg:py-0">
              <Button
                className={`${
                  path === "/solutions" ? "active-link" : ""
                } font-semibold text-theme-blue text-lg pl-10 2xl:text-2xl no-underline hover:underline`}
                type="link"
                href="/solutions"
              >
              Solutions
              </Button>
            </li>

            <li 
                className="flex items-center py-4 lg:py-0 relative" // Added relative to parent for positioning dropdown
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Button
                  className={`${
                    path === "/career" ? "active-link" : ""
                  } font-semibold text-theme-blue text-lg pl-8 2xl:text-2xl no-underline hover:underline`}
                  type="button"
                >
                  Careers
                </Button>

                {isDropdownOpen && (
                  <ul className="absolute top-full p-2 bg-white border border-gray-300 rounded-lg shadow-lg z-50">
                    <li className="px-4 py-2 hover:bg-gray-200">
                      <Button
                        className={`${
                          path === "/Jobs" ? "active-link" : ""
                        } font-semibold text-theme-blue text-lg sm:text-xl P-1 no-underline hover:underline`}
                        type="link"
                        href="/Jobs"
                      >
                        Jobs
                      </Button>
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-200">
                      <Button
                        className={`${
                          path === "/Internship" ? "active-link" : ""
                        } font-semibold text-theme-blue text-lg sm:text-xl P-1 no-underline hover:underline`}
                        type="link"
                        href="/Internship"
                      >
                        Internship
                      </Button>
                    </li>
                  </ul>
                )}
              </li>

           
            <li className="flex items-center ml-auto">
              <Button
                className="font-semibold text-lg md:px-8 md:py-3 lg:px-8 lg:py-3 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200 flex items-center"
                type="link"
                href="/discuss-project"
              >
                Contact
                <FaPhoneAlt size={25} className="ml-2 text-xl animate-bounce" />
              </Button>
            </li>
          </ul>

          {/* Button visible on large screens */}
          <li className="hidden lg:block  p-5">
              <button
                className="ml-4 lg:ml-auto bg-theme-purple text-white rounded-full p-2 border-2 border-theme-purple hover:bg-dark-theme-purple transition duration-200"
                onClick={handleButtonClick}
              >
                <svg
                  className="w-8 h-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    className={`${isCollapse ? "hidden" : "block"}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                  <path
                    className={`${!isCollapse ? "hidden" : "block"}`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </li>
        </div>
      </div>

      {/* Mobile menu transition */}
      <Transition
          show={isCollapse}
          enter="transition-opacity duration-400"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-400"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 bg-white z-50 border-b-2 border-gray-300 ${isCollapse ? "block" : "hidden"}`}
          >
            <button
              className="absolute right-10 top-6 lg:top-12 lg:right-20 text-theme-blue focus:outline-none"
              onClick={handleCloseMenu}
            >
              <FaTimes className="w-6 h-6" />
            </button>

            <div className="flex justify-center items-center mt-8">
              <ul className="grid-flow-col gap-4 text-theme-blue tracking-widest p-6">
                <li className="py-2 bg-white flex justify-center items-center">
                  <Button
                    className={`${
                      path === "/services" ? "active-link" : ""
                    } font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="link"
                    href="/services"
                  >
                    Services
                  </Button>
                </li>
                <li className="py-2 bg-white flex justify-center items-center">
                  <Button
                    className={`${
                      path === "/about" ? "active-link" : ""
                    } font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="link"
                    href="/about"
                  >
                    About Us
                  </Button>
                </li>
               
                <li className="py-2 bg-white flex justify-center items-center">
                  <Button
                    className={`${
                      path === "/solution" ? "active-link" : ""
                    } font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="link"
                    href="/solutions"
                  >
                    Solutions
                  </Button>
                </li>
                <li className="py-2 bg-white flex justify-center items-center"
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
                
                >
                  <Button
                    className={`${
                      path === "/career" ? "active-link" : ""
                    } flex items-center font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="button"
                  >
                    Careers
                    <IoIosAdd className="ml-2" />
                  </Button>
                  {isDropdownOpen && (
                  <ul className="absolute ml-52 lg:ml-[360px] mt-16 p-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                    <li className="px-4 py-2 hover:bg-gray-200">
                    <Button
                      className={`${
                        path === "/Jobs" ? "active-link" : ""
                      } font-semibold text-theme-blue text-lg sm:text-xl P-1 no-underline hover:underline`}
                      type="link"
                      href="/Jobs"
                    >
                      Jobs
                    </Button>
                     
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-200">
                      <Button
                        className={`${
                          path === "/Internship" ? "active-link" : ""
                        } font-semibold text-theme-blue text-lg sm:text-xl P-1 no-underline hover:underline`}
                        type="link"
                        href="/Internship"
                      >
                        Internship
                      </Button>
                    </li>

                  </ul>
                )}

                </li>
                <li className="py-2 bg-white flex justify-center items-center">
                  <Button
                    className={`${
                      path === "/product" ? "active-link" : ""
                    } font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="link"
                    href="/product"
                  >
                    Products
                  </Button>
                </li>

                <li className="py-2 bg-white flex justify-center items-center">
                  <Button
                    className={`${
                      path === "/blogs" ? "active-link" : ""
                    } font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="link"
                    href="/blogs"
                  >
                    Blogs
                  </Button>
                </li>

                <li className="py-2 bg-white flex justify-center items-center">
                  <Button
                    className={`${
                      path === "/discuss-project" ? "active-link" : ""
                    } font-medium sm:text-4xl px-4 no-underline hover:underline`}
                    type="link"
                    href="/discuss-project"
                  >
                    Contact Us
                  </Button>
                </li>

            

                {/* Add more items to complete the 3x3 grid if needed */}
              </ul>
            </div>
          </div>
        </Transition>

    </header>
  );
}
